import React from 'react';
import { useState } from 'react';
import {
  Alert,
  Button,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
} from 'reactstrap';
import Result from '../displaySecret/Result';
import { encryptMessage, postSecret, randomString } from '../utils/utils';
import { useTranslation } from 'react-i18next';

const Create: React.FC = () => {
  const [expiration, setExpiration] = useState(3600);
  const [error, setError] = useState('');
  const [secret, setSecret] = useState('');
  const [onetime, setOnetime] = useState(true);
  const [loading, setLoading] = useState(false);
  const [uuid, setUUID] = useState('');
  const [prefix, setPrefix] = useState('');
  const [password, setPassword] = useState('');
  const [specifyPassword, setSpecifyPassword] = useState(false);

  const { t } = useTranslation();

  const setSpecifyPasswordAndUpdatePassword = (
    customPassword: boolean,
  ): void => {
    setSpecifyPassword(customPassword);
    if (!customPassword) {
      // Clear the manual password if it should be generated.
      setPassword('');
    }
  };

  const submit = async (): Promise<void> => {
    if (!secret) {
      return;
    }
    setLoading(true);
    setError('');
    try {
      // Use the manually entered password, or generate one
      const pw = password.length ? password : randomString();
      const { data, status } = await postSecret({
        expiration,
        message: await encryptMessage(secret, pw),
        one_time: onetime,
      });
      if (status !== 200) {
        setError(data.message);
      } else {
        setUUID(data.message);
        setPrefix(password.length ? 'c' : 's');
        setPassword(pw);
      }
    } catch (e) {
      setError(e.message);
    }
    setLoading(false);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.ctrlKey && event.key === 'Enter') {
      submit();
    }
  };

  return (
    <div className="text-center">
      <h1>{t('Encrypt message')}</h1>
      <Error message={error} onClick={() => setError('')} />
      {uuid ? (
        <Result uuid={uuid} password={password} prefix={prefix} />
      ) : (
        <Form>
          <FormGroup>
            <Label>{t('Secret message')}</Label>
            <Input
              type="textarea"
              name="secret"
              rows="4"
              autoFocus={true}
              placeholder={t('Message to encrypt locally in your browser')}
              onChange={(e) => setSecret(e.target.value)}
              value={secret}
              onKeyDown={onKeyDown}
            />
          </FormGroup>
          <Lifetime expiration={expiration} setExpiration={setExpiration} />
          <div className="row">
            <OneTime setOnetime={setOnetime} onetime={onetime} />
            <SpecifyPasswordToggle
              setSpecifyPassword={setSpecifyPasswordAndUpdatePassword}
              specifyPassword={specifyPassword}
            />
          </div>
          {specifyPassword ? (
            <SpecifyPasswordInput
              setPassword={setPassword}
              password={password}
            />
          ) : (
            ''
          )}
          <Button
            disabled={
              loading ||
              secret.length === 0 ||
              (specifyPassword && password.length === 0)
            }
            color="primary"
            size="lg"
            block={true}
            onClick={() => submit()}
          >
            {loading ? (
              <span>{t('Encrypting message...')}</span>
            ) : (
              <span>{t('Encrypt Message')}</span>
            )}
          </Button>
        </Form>
      )}
    </div>
  );
};

type OneTimeProps = {
  readonly onetime: boolean;
  readonly setOnetime: React.Dispatch<React.SetStateAction<boolean>>;
};

export const OneTime: React.FC<OneTimeProps> = (props) => {
  const { t } = useTranslation();
  const { onetime, setOnetime } = props;
  return (
    <FormGroup className="offset-md-3 col-md-3">
      <Input
        type="checkbox"
        id="enable-onetime"
        onChange={() => setOnetime(!onetime)}
        checked={onetime}
      />
      <Label for="enable-onetime">{t('One-time download')}</Label>
    </FormGroup>
  );
};

type SpecifyPasswordToggleProps = {
  readonly specifyPassword: boolean;
  readonly setSpecifyPassword: any;
};

export const SpecifyPasswordToggle: React.FC<SpecifyPasswordToggleProps> = (
  props,
) => {
  const { t } = useTranslation();
  const { specifyPassword, setSpecifyPassword } = props;

  return (
    <FormGroup className="col-md-3">
      <Input
        type="checkbox"
        id="specify-password"
        onChange={() => setSpecifyPassword(!specifyPassword)}
        checked={!specifyPassword}
        title={t('The decryption key is randomly generated by default')}
      />
      <Label
        for="specify-password"
        title={t('The decryption key is randomly generated by default')}
      >
        {t('Generate decryption key')}
      </Label>
    </FormGroup>
  );
};

type SpecifyPasswordInputProps = {
  readonly password: string;
  readonly setPassword: React.Dispatch<React.SetStateAction<string>>;
};

export const SpecifyPasswordInput: React.FC<SpecifyPasswordInputProps> = (
  props,
) => {
  const { t } = useTranslation();
  const { password, setPassword } = props;

  return (
    <FormGroup>
      <Input
        type="text"
        id="password"
        name="password"
        placeholder={t('Manually enter decryption key')}
        autoComplete="off"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
      />
    </FormGroup>
  );
};

type LifeTimeProps = {
  readonly expiration: number;
  readonly setExpiration: React.Dispatch<React.SetStateAction<number>>;
};

export const Lifetime: React.FC<LifeTimeProps> = (props) => {
  const { expiration, setExpiration } = props;
  const { t } = useTranslation();
  const buttons = [];
  for (const i of [
    {
      duration: 3600,
      name: '1h',
      text: t('One Hour'),
    },
    {
      duration: 86400,
      name: '1d',
      text: t('One Day'),
    },
    {
      duration: 604800,
      name: '1w',
      text: t('One Week'),
    },
  ]) {
    buttons.push(
      <FormGroup key={i.name} check={true} inline={true}>
        <Label check={true}>
          <Input
            type="radio"
            name={i.name}
            value={i.duration}
            onChange={(e) => setExpiration(+e.target.value)}
            checked={expiration === i.duration}
          />
          {i.text}
        </Label>
      </FormGroup>,
    );
  }

  return (
    <FormGroup tag="fieldset">
      <FormText color="muted">
        {t('The encrypted message will be deleted automatically after')}
      </FormText>
      {buttons}
    </FormGroup>
  );
};

type ErrorProps = {
  readonly message: string;
  readonly onClick?: () => void;
};

export const Error: React.FC<ErrorProps> = (props) =>
  props.message ? (
    <Alert color="danger" {...props}>
      {props.message}
    </Alert>
  ) : null;

export default Create;
